<template>
  <div>
    <ViewOrderDetails
      :orderId="orderId"
    />
  </div>
</template>

<script>
import ViewOrderDetails from "../../../components/WebStore/orders/OrderDetails";

export default {
  name: 'View-Order-Details',
  components:{
    ViewOrderDetails
  },
  data: () => ({
    orderId: '',
    supplierId:''
  }),
  methods:{},
  async created() {
    this.orderId = this.$route.params.orderId;
  }
};
</script>
